import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import MenuItem from '@mui/material/MenuItem';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import Forms from './form';
// import Tableworkorder from './table';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [formData, setFormData] = useState({
    slno: '',
    dateofpurchase: null,
    make: '',
    poNumber: '',
    invoiceNumber: '',
    date: '',
    warrentystart: '',
    warrentyend: '',
    warrentyPeriod: '',
    expiryNotification: '',
    vendorSupplier: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    dateinvoicenum: null,
    dateinvoice: null
  });


  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmitForm = () => {
    console.log(formData);
  }
  const handleClearForm = () => {
    setFormData({
      pmno: '',
      area: '',
      facility: '',
      equipment: '',
      subequipment: '',
      device: '',
      instrumenttag: '',
      jobnature: '',
      lastperformed: '',
      frequency: '',
      tolerence: '',
      repeat: '',
      advancenotification: '',
      status: ''
    });
  };



  const headerProps = {
    open, handleDrawerOpen
  }
  const menuProps = {
    setOpen, open
  }
  const tableProps = {
    setOpenDialog
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  return (


    <Box sx={{ display: 'flex' }}>

      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
        <Grid className='bg-slate-100 '>
          <DrawerHeader />
          {/* <Forms /> */}
          <div className='rounded p-3 text-lg text-left font-semibold'>
          <h2>Daily Logs</h2>
          </div>
          <Box className="w-full">
            <Grid className='bg-slate-100 '>
              <form>
                <div className='text-left bg-white  m-2 border-solid border rounded'>
                  <div className='border-slate-300 rounded-md  '>
                    <h3 className='border-b-2  p-3 bg-gray-200 '>Logs</h3>
                  </div>
                  <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2} className='p-3'>
                  <Grid item xs={12} md={3} >
                    <TextField size='small' label="Order Number" variant="outlined"
                      name="orderNumber"
                     
                      sx={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order Type</InputLabel>
                      <Select className='mms-select'
                        name="ordertype"
                       
                        label="ordertype"
                     

                      >
                      
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={3} >
                  <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order From</InputLabel>
                      <Select className='mms-select'
                        name="orderfrom"
                       
                        label="ordertype"
                      

                      >
                      
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} >
                  <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order To</InputLabel>
                      <Select className='mms-select'
                        name="orderto"
                       
                        label="ordertype"
                       

                      >
                       
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={3} >
                    <TextField size='small' label="Short Job Description" variant="outlined"
                      name="shortJobDescription"
                     
                      sx={{ width: '100%' }} />
                  </Grid>
                
                <Grid item xs={12} md={9} >
                  <TextField aria-label="minimum height" sx={{ width: '100%' }} multiline rows={4}
                    label="Detailed Job Description"
                   
                    name='detailedJobDescription'
                   
                  />
                </Grid>
                </Grid>


                </div>
                <div className='text-left bg-white  m-2 border-solid border rounded' >
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-2 bg-gray-200'>Assets</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Plan / Facility</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  
                  label="Plan / Facility"
                 
                >
                  
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="equipment">Equipment</InputLabel>
                <Select className='mms-select'
                  name="equipment"
                 
                  label="Equipment"
                
                >
                 
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="subEquipment">Sub -Equipment</InputLabel>
                <Select className='mms-select'
                  name="subEquipment"
                 
                  label="Sub -Equipment"
                 
                >
                 
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="deviceInstrument">Device / Instrument</InputLabel>
                <Select className='mms-select'
                  name="deviceInstrument"
                
                  label="Device / Instrument"
                 
                >
                 
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="Tag">Tag</InputLabel>
                <Select className='mms-select'
                  name="tag"
                
                  label="Tag"
                  
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>



              </form>
            </Grid>
          </Box>



        </Grid>
      </Box>
    </Box>
  );
}
