import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { setFullLoad, setSnackMsg } from "../../redux/actions/common";
import { baseUrl, headers } from "../../constants";
import { getDropDown } from "../../redux/selector";
import { fetchDropDown } from "../../utils";
import {
  getDDFacilityCall, getDDEquCall, getDDSubEquCall,
  getDDDeviceCall, getDDJobProCall, getDDJobNatureCall,
  getDDWorkCall, getDDEmployeeCall
} from "../../redux/actions/dropDown";
import { dropDownNode } from "../../utils";

export default function Forms({ handleClose, formDataObj, fetchTableData }) {
  const dispatch = useDispatch();
  const dropDownState = useSelector(getDropDown);

  const [dropDownVal, setDropDownVal] = useState(dropDownState || {});
  const [age, setAge] = React.useState('');
  const [addSimilar, setAddSimilar] = useState(false);
  const isEdit = formDataObj?.formType === 'edit';
  const isView = formDataObj?.formType === 'view' && !addSimilar;
  const viewStyle = { pointerEvents: isView ? "none" : false };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // const [formData, setFormData] = useState(formDataObj);
  const [formData, setFormData] = useState({
    detailedJobDescription: '',
  });

  const handleInputChange = (e, a, b, c) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const handleSubmitForm = () => {
    dispatch(setFullLoad(true));
    console.log("add- work-order --", formData);
    const formDataObj = {
      ...formData,
      add_by: 1
    };
    delete formDataObj.formType;
    fetch(`${baseUrl}/api/work/work_order${isEdit ? '/edit' : ''}`, {
      method: "POST",
      body: JSON.stringify(formDataObj),
      headers
    })
      .then((response) => {
        return response.json();
      })
      .then(data => {
        console.log("add- work-order --", data.message);
        const isError = data?.statusCode !== 200;
        dispatch(setFullLoad(false));
        dispatch(setSnackMsg({
          open: true,
          error: isError,
          msg: data.message
        }));
        if (!isError) {
          handleClose();
          fetchTableData();
        }
      });
  }
  const handleClearForm = () => {
    setFormData({
      formType: formDataObj.formType,
      orderNumber: '',
      orderType: '',
      shortJobDescription: '',
      detailedJobDescription: '',
      area: '',
      planFacility: '',
      equipment: '',
      subEquipment: '',
      deviceInstrument: '',
      tag: '',
      jobPriority: '',
      createdBy: '',
      reportedBy: '',
      assignedTo: '',
      orderFrom: null,
      orderTo: null
    });
  };
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
   
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};   
  `,
  );

  const [fields, setFields] = useState([{ value: '' }]);

  useEffect(() => {
    fetchDropDown(dispatch, [
      getDDFacilityCall, getDDEquCall, getDDSubEquCall,
      getDDDeviceCall, getDDJobProCall, getDDJobNatureCall,
      getDDWorkCall, getDDEmployeeCall
    ]);
  }, [])

  useEffect(() => {
    setDropDownVal(dropDownState || {});
  }, [dropDownState]);

  const [alignment, setAlignment] = React.useState('repeat');
  
  const [dwmyalignment, setDwmyAlignment] = React.useState('day');

  
  const handletoggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
 

  const handledwmyChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setDwmyAlignment(newAlignment);
    }
  };
  const [dwmalignment, dwmsetAlignment] = React.useState('day');
  
  

  const handledwmChange = (event, newAlignment) => {
    dwmsetAlignment(newAlignment);
  };
  const maxLength = 225;
  const currentLength = formData.detailedJobDescription.length;

  return (
    <Box className="w-full">
      <Grid className='bg-slate-100 '>
        <form>
          <div className='text-left bg-white  m-2 border-solid border rounded' style={viewStyle}>
            <div className='border-slate-300 flex  bg-gray-200 items-center'>
              <h3 className='border-b-2  p-2'>Order Details</h3>
              <div className="ml-auto">
                <Button className="dig_btn">Fault List</Button>
                <Button className="dig_btn">Add</Button>
              </div>
            </div>
            <div className=' p-3 mms-date-field'>
              <Grid xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
                  <Grid item xs={12} md={3} >
                    <TextField size='small' label="Order Number" variant="outlined"
                      name="orderNumber"
                      value={formData.orderNumber}
                      sx={{ width: '100%' }}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={3} >
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order Type</InputLabel>
                      <Select className='mms-select'
                        name="ordertype"
                        value={formData.ordertype}
                        label="ordertype"
                        onChange={handleInputChange}

                      >
                        {dropDownNode(dropDownVal.orderType || [])}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={3} >
                  <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order From</InputLabel>
                      <Select className='mms-select'
                        name="orderfrom"
                        value={formData.ordertype}
                        label="ordertype"
                        onChange={handleInputChange}

                      >
                        {dropDownNode(dropDownVal.orderType || [])}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} >
                  <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="planFacility">Order To</InputLabel>
                      <Select className='mms-select'
                        name="orderto"
                        value={formData.ordertype}
                        label="ordertype"
                        onChange={handleInputChange}

                      >
                        {dropDownNode(dropDownVal.orderType || [])}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={12} >
                    <TextField size='small' label="Short Job Description" variant="outlined"
                      name="shortJobDescription"
                      value={formData.shortJobDescription}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleInputChange} sx={{ width: '100%' }} />
                  </Grid>
                
                <Grid item xs={12} md={12} >
                <div>
      <TextField
        aria-label="minimum height"
        sx={{ width: '100%' }}
        multiline
        rows={4}
        label="Detailed Job Description"
        value={formData.detailedJobDescription}
        name="detailedJobDescription"
        onChange={handleInputChange}
        inputProps={{ maxLength }} // Restrict the maximum length
      />
      <Typography
        variant="body2"
        sx={{
          textAlign: 'right',
          color: currentLength >= maxLength * 0.8 ? 'red' : 'gray', // Change color dynamically
          mt: 1,
        }}
      >
        {`${currentLength}/${maxLength}`} {/* Character counter */}
        Characters
      </Typography>
    </div>
                </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className='text-left bg-white  m-2 border-solid border rounded' style={viewStyle}>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-2 bg-gray-200'>Assets</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="planFacility">Plan / Facility</InputLabel>
                <Select className='mms-select'
                  name="planFacility"
                  value={formData.planFacility}
                  label="Plan / Facility"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.facility_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="equipment">Equipment</InputLabel>
                <Select className='mms-select'
                  name="equipment"
                  value={formData.equipment}
                  label="Equipment"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.equipment_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="subEquipment">Sub -Equipment</InputLabel>
                <Select className='mms-select'
                  name="subEquipment"
                  value={formData.subEquipment}
                  label="Sub -Equipment"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.sub_equipment_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="deviceInstrument">Device / Instrument</InputLabel>
                <Select className='mms-select'
                  name="deviceInstrument"
                  value={formData.deviceInstrument}
                  label="Device / Instrument"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.device_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="Tag">Tag</InputLabel>
                <Select className='mms-select'
                  name="tag"
                  value={formData.tag}
                  label="Tag"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

            </div>
          </div>
          <div className='text-left bg-white  m-2 border-solid border rounded' style={viewStyle}>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-2 bg-gray-200'>User Details</h3>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-3  p-3'>
              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="jobPriority">Job Priority</InputLabel>
                <Select className='mms-select'
                  name="jobPriority"
                  value={formData.jobPriority}
                  label="Job Priority"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.job_priority_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="area">Job Nature</InputLabel>
                <Select className='mms-select'
                  name="area"
                  value={formData.area}
                  label="Job Nature"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.job_nature_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="createdBy">Created By</InputLabel>
                <Select className='mms-select'
                  name="createdBy"
                  value={formData.createdBy}
                  label="Created By"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.employee_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="reportedBy">Reported By</InputLabel>
                <Select className='mms-select'
                  name="reportedBy"
                  value={formData.reportedBy}
                  label="Reported By"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.employee_id || [])}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 207 }}>
                <InputLabel id="AssignedTo">Assigned To</InputLabel>
                <Select className='mms-select'
                  name="assignedTo"
                  value={formData.assignedTo}
                  label="Assigned To"
                  onChange={handleInputChange}
                >
                  {dropDownNode(dropDownVal.employee_id || [])}
                </Select>
              </FormControl>

            </div>
          </div>
          {(isEdit || isView) ? <div className='text-left bg-white  m-2 border-solid border rounded' style={viewStyle}>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-2 bg-gray-200'>Scheduled work order</h3>
            </div>
            <div className='p-3'>
              <Grid xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2} className="items-baseline">
                  <Grid item xs={12} md={6} container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                      <h4 className="text-right">Repeat Type</h4>
                    </Grid>
                    <Grid item xs={12} md={8} >

                      <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handletoggleChange}
                        aria-label="Platform"
                        className="mms-select-one-btn"
                      >
                        <ToggleButton value="repeat">Repeat</ToggleButton>
                        <ToggleButton value="Onetime">One Time</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={4} >
                      <h4 className="text-right">Intervals<br/> (Whichever come first) :</h4>
                    </Grid>
                    {alignment === 'repeat' && (
        <>
                    <Grid item xs={12} md={8} >
                      <FormControlLabel control={<Checkbox defaultChecked />} label="Every" />
                      <TextField size='small' label="Hours" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        sx={{width:'110px'}}
                        onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={8} className="lg:flex">
                      <FormControlLabel control={<Checkbox defaultChecked />} label="Every" />
                      <TextField size='small' label="" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        sx={{width:'100px',marginRight:'5px'}}
                        onChange={handleInputChange} />
                      <ToggleButtonGroup
                        color="primary"
                        value={dwmyalignment}
                        exclusive
                        onChange={handledwmyChange}
                        aria-label="Platform"
                        className="mms-select-one-btn"
                      >
                        <ToggleButton value="day">Day</ToggleButton>
                        <ToggleButton value="Week">Week</ToggleButton>
                        <ToggleButton value="Month">Month</ToggleButton>
                        <ToggleButton value="Year">Year</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    </>
      )}
      {alignment === 'Onetime' && (
        <>
                    <Grid item xs={12} md={8} >
                      <FormControlLabel control={<Checkbox defaultChecked />} label="At" />
                      <TextField size='small' label="Hours" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        sx={{width:'110px'}}
                        onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={8} className="lg:flex">
                    <FormControlLabel control={<Checkbox defaultChecked />} label="On" />
                    <FormControl sx={{ width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            name="orderFrom"
                            value={dayjs(formData.orderFrom || null)}
                            label="OrderFrom"
                            onChange={(newValue) => {
                              const dateFormatedVal = dayjs(newValue).format('YYYY-MM-DD');
                              handleInputChange({
                                target: {
                                  value: dateFormatedVal,
                                  name: "orderFrom"
                                }
                              })
                            }}
                            format='DD-MM-YYYY'
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                    </Grid>
                    </>
      )}
                    <Grid item xs={12} md={4}>
                      <h4 className="text-right">Assign to: </h4>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField size='small' label="" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        sx={{width:'100%'}}
                        onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <h4 className="text-right">Automatically Generate Wo: </h4>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControlLabel control={<Checkbox defaultChecked />} label="Yes" />

                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                      <h4 className="text-right">Notify before :</h4>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControlLabel control={<Checkbox defaultChecked />} label="Every" />
                      <TextField size='small' label="Hours" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        onChange={handleInputChange} sx={{ width: '100px', marginRight: '5px' }} />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8} className="lg:flex">
                      <TextField size='small' label="" variant="outlined"
                        name="hours"
                        value={formData.hours}
                        onChange={handleInputChange} sx={{ width: '100px' }} />
                      <ToggleButtonGroup
                        color="primary"
                        value={dwmalignment}
                        exclusive
                        onChange={handledwmChange}
                        aria-label="Platform"
                        className="mms-select-one-btn"
                      >
                        <ToggleButton value="day">Day</ToggleButton>
                        <ToggleButton value="Week">Week</ToggleButton>
                        <ToggleButton value="Month">Month</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>


                </Grid>


              </Grid>
            </div>
          </div> : ''}
          {(isEdit || isView) ? <div className='text-left bg-white  m-2 border-solid border rounded' style={viewStyle}>
            <div className='border-slate-300 rounded-md  '>
              <h3 className='border-b-2  p-2 bg-gray-200'>EDIT & CLOSE WORK ORDER</h3>
            </div>
            <div className='p-3'>
              <Grid xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="jobPermit">Job Permit</InputLabel>
                      <Select className='mms-select'
                        name="jobPermit"
                        value={formData.jobPermit}
                        label="Job Permit"
                        onChange={handleInputChange}
                      >
                        {dropDownNode(dropDownVal.work_permit_id || [])}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Job Permit No" variant="outlined" sx={{ width: '100%' }}
                      name="jobpermitno"
                      value={formData.jobpermitno}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Start" variant="outlined"
                      name="start" sx={{ width: '100%' }}
                      value={formData.start}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="End" variant="outlined"
                      name="End" sx={{ width: '100%' }}
                      value={formData.end}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Total Duration" variant="outlined"
                      name="TotalDuration" sx={{ width: '100%' }}
                      value={formData.TotalDuration}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Break Down Start" variant="outlined"
                      name="BreakDownStart" sx={{ width: '100%' }}
                      value={formData.BreakDownStart}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Break Down End" variant="outlined"
                      name="BreakDownEnd" sx={{ width: '100%' }}
                      value={formData.BreakDownEnd}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Total Break Down Duration" variant="outlined"
                      name="BreakDownDuration" sx={{ width: '100%' }}
                      value={formData.BreakDownDuration}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField size='small' label="Vendor / Contractor" variant="outlined"
                      name="VendorContractor" sx={{ width: '100%' }}
                      value={formData.VendorContractor}
                      onChange={handleInputChange} />
                  </Grid>

                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                  <TextField aria-label="minimum height" label="Root Case / Analysis" sx={{ width: '100%' }} multiline rows={3} />
                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                  <TextField aria-label="minimum height" label="Action Taken" sx={{ width: '100%' }} multiline rows={3} />
                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                  <TextField aria-label="minimum height" label="Material Consumed" sx={{ width: '100%' }} multiline rows={3} />
                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                  <TextField aria-label="minimum height" label="Special Tools Used" sx={{ width: '100%' }} multiline rows={3} />
                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                  <TextField aria-label="minimum height" label="Safety Precaution Taken" sx={{ width: '100%' }} multiline rows={3} />
                </Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}> <TextField aria-label="minimum height" label="Special Notes" sx={{ width: '100%' }} multiline rows={3} /></Grid>
                <Grid item xs={12} md={12} rowSpacing={3} columnSpacing={2}> <TextField aria-label="minimum height" label="Remarks" sx={{ width: '100%' }} multiline rows={3} /></Grid>
              </Grid>
            </div>
          </div> : ''}

         

          <div className='mt-5 flex justify-center'>
            {(!isView || addSimilar) ? <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div> : ''}
            {!addSimilar && <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' onClick={() => {
                setAddSimilar(true);
              }}>
                Add Similar
              </Button>
            </div>}
            {(!isView || addSimilar) ? <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div> : ''}

          </div>
        </form>
      </Grid>
    </Box>
  );
}