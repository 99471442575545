import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import './workorder.css';

import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import Forms from './form';
import Tableworkorder from './table';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function MiniDrawer() {

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };



  

 
const headerProps={
  open,handleDrawerOpen
}
const menuProps={
  setOpen,open
}
const tableProps={
  setOpenDialog
}
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    
       
    <Box sx={{ display: 'flex' }}>
      
      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }} className='bg-slate-100 mms-cnt-main overflow-hidden'>
      <Grid className='bg-slate-100 '>
      <DrawerHeader />
      {/* <Forms /> */}
      <div className='rounded p-3 text-lg text-left font-semibold'>
          <h2>Issued Workorder List</h2>
          </div>

          <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='mms-tabs overflow-y-auto'>
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Open" {...a11yProps(1)} />
          
          <Tab label="Closed Order" {...a11yProps(2)} />
          <Tab label="Hold Order" {...a11yProps(3)} />
          <Tab label="In progress" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box   className='bg-slate-100 mms-table-sec'>
      <Tableworkorder {...tableProps} />
      </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      Open
      </CustomTabPanel>
     
      <CustomTabPanel value={value} index={2}>
      Closed Order
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      Hold Order
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      In progress
      </CustomTabPanel>
    </Box>

      <BootstrapDialog
      className="mms-full-dig"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog?.formType}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='bg-sky-600 text-white'>
        New  Work Order Request
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Forms handleClose={handleClose} formDataObj={openDialog} /> 
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </BootstrapDialog>
      
   
       </Grid>
      </Box>
    </Box>
  );
}
